export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [6,[2]],
		"/(app)/admin": [7,[2,3]],
		"/(app)/admin/settings": [8,[2,3]],
		"/(app)/assistants": [9,[2]],
		"/(app)/assistants/create": [10,[2]],
		"/(app)/assistants/edit": [11,[2]],
		"/auth": [39],
		"/(app)/company": [13,[2]],
		"/(app)/company/add-member": [14,[2]],
		"/(app)/company/groups": [15,[2]],
		"/(app)/company/settings": [16,[2]],
		"/(app)/company/user/[id]": [17,[2]],
		"/(app)/c/[id]": [12,[2]],
		"/error": [40],
		"/(app)/groups": [18,[2]],
		"/payments/payment-failed": [41],
		"/payments/payment-success": [42],
		"/(app)/playground": [19,[2,4]],
		"/(app)/prompts": [20,[2]],
		"/(app)/prompts/create": [21,[2]],
		"/(app)/prompts/edit": [22,[2]],
		"/s/[id]": [43],
		"/(app)/user": [23,[2]],
		"/(app)/user/change-password": [24,[2]],
		"/(app)/workspace": [25,[2,5]],
		"/(app)/workspace/documents": [26,[2,5]],
		"/(app)/workspace/functions": [27,[2,5]],
		"/(app)/workspace/functions/create": [28,[2,5]],
		"/(app)/workspace/functions/edit": [29,[2,5]],
		"/(app)/workspace/models": [30,[2,5]],
		"/(app)/workspace/models/create": [31,[2,5]],
		"/(app)/workspace/models/edit": [32,[2,5]],
		"/(app)/workspace/prompts": [33,[2,5]],
		"/(app)/workspace/prompts/create": [34,[2,5]],
		"/(app)/workspace/prompts/edit": [35,[2,5]],
		"/(app)/workspace/tools": [36,[2,5]],
		"/(app)/workspace/tools/create": [37,[2,5]],
		"/(app)/workspace/tools/edit": [38,[2,5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';